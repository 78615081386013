import React, { useState, useEffect, useRef } from "react";

export const QuoteForm = () => {
    const [isVisible, setIsVisible] = useState(false);
    const formRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );
        if (formRef.current) observer.observe(formRef.current);

        return () => observer.disconnect();
    }, []);

    return (
        <div ref={formRef} className="w-full h-[310px]">
            {isVisible ? (
                <iframe
                    src="https://form.jotformeu.com/211048518814454"
                    title="Free Quote Request Form"
                    className="w-full h-[310px] border-0"
                    loading="lazy"
                />
            ) : (
                <div className="w-full h-full flex items-center justify-center bg-gray-100">
                    <p>Loading form...</p>
                </div>
            )}
        </div>
    );
};
