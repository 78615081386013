import React, { memo } from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// import JotformEmbed from 'react-jotform-embed';
import { QuoteForm } from "../quoteForm";

const HeroSection = memo(({ data }) => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false
    };
    // return (
    //     <div className="flex md:flex-row flex-col gap-8 mb-16 items-start">
    //         <div className="md:w-8/12 relative">
    //             <div className="relative">
    //                 <StaticImage src="../../assets/images/slider.jpg" alt="" className="slider" />
    //             </div>
    //             <div className="bg-white w-full mx-auto text-center">
    //                 <div className="mx-auto p-4">
    //                     <h3 className="md:text-2xl text-xl font-semibold mb-2 text-gray-600">Toronto & GTA Fence Installers Offering a large variety of all types of fence to go with your home, yard, or ranch</h3>
    //                     <h2 className="md:text-3xl text-2xl font-bold border-b-4 border-cyan-400 pb-2 mt-2 mb-2 text-gray-600">Get A Free Online Estimate Today!</h2>
    //                     <p className="text-base font-bold mb-1 text-gray-600 max-w-full">Please feel free to call us to discuss the details of your project.</p>
    //                     <p className="text-base font-bold mb-1 text-gray-600 max-w-full">Thank you!</p>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="md:w-4/12 w-full shadow-2xl px-4 pb-4 border-2 border-indigo-600">
    //             <h2 className="title text-3xl font-medium mt-5 mb-2.5 pb-5 text-center">Quick Quote</h2>
    //             <JotformEmbed src="https://form.jotformeu.com/211048518814454" />
    //         </div>
    //     </div>
    // )
    return (
        <div className="max-w-6xl mx-auto relative hero_slider md:h-full h-[700px]">
            <Slider {...settings}>
                {data.map((slide, index) => (
                    <div key={index} className="h-full relative ">
                        <GatsbyImage
                            image={getImage(slide.image)}
                            alt={slide.alt}
                            className="w-full md:h-full h-[290px] object-cover"
                        // loading="eager"
                        />
                    </div>
                ))}
            </Slider>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-20 w-full h-full">
                <div className="md:absolute relative md:top-20 md:left-0 md:right-0 max-w-7xl w-full flex md:flex-row flex-col">
                    <div className="md:w-2/3 w-full  text-black flex flex-col items-start justify-center">
                        <div className="md:p-5 md:m-5 p-3 m-3 wp-full flex flex-col items-start justify-center">
                            <h1 className="text-4xl md:text-5xl font-bold mb-4 text-left 
        text-transparent bg-clip-text 
        bg-gradient-to-b from-gray-900 to-gray-800
        drop-shadow-[2px_3px_3px_rgba(255,255,255,0.5)]
        ">
                                Toronto’s #1 Vinyl Fence Installers
                            </h1>
                            <p className="text-lg md:text-2xl font-medium 
         text-transparent bg-clip-text 
        bg-gradient-to-b from-gray-900 to-gray-800
        drop-shadow-[2px_3px_3px_rgba(255,255,255,0.5)]">
                                Get a Free Quote in Minutes! Secure, Stylish & Maintenance-Free Fencing for Your Property.
                                <span className="font-bold">Limited-Time Offer</span> – Book Your Free Consultation Today!
                            </p>
                        </div>
                    </div>
                    <div className="md:w-1/3 bg-white w-full md:p-5 md:m-5 p-3">
                        <div className="w-full">
                            <h2 className="title text-3xl font-medium  mb-2.5 pb-5 text-center">Quick Quote</h2>
                            <div className="w-full">
                                {/* <iframe
                                src="https://form.jotformeu.com/211048518814454"
                                title="Free Quote Request Form"
                                className="w-full h-[310px] border-0"
                                loading="lazy"
                            ></iframe> */}
                                <QuoteForm />
                            </div>
                            {/* <JotformEmbed src="https://form.jotformeu.com/211048518814454" /> */}

                        </div>
                    </div>
                </div>
            </div>



        </div>
    );

})

export default HeroSection